<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left">
        <div class="banner-cont">
          <h2>Choose your Welcome Bonus</h2>
          <div class="slider-block">
            <div class="slider-img"></div>
            <div class="slider-content">
              <h2>1st Dep 100% + 100FS</h2>
            </div>
            <div class="arrow_block">
              <button class="arrow_left">
                  <span>
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
                      ></path>
                    </svg>
                  </span>
              </button>
              <button class="arrow_right">
                  <span>
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
                      ></path>
                    </svg>
                  </span>
              </button>
            </div>
          </div>
          <div class="banner-check-block">
            <input id="banner-check-block" type="checkbox" />
            <label for="banner-check-block"
            >Продолжить без приветственного бонуса</label
            >
          </div>
        </div>
      </div>
      <div class="registration-form modal-right">
        <div class="form-header">
          <h2>Регистрация</h2>
        </div>

        <div class="mobile slider-block">
          <div class="slider-img"></div>
          <div class="slider-content">
            <h2>1st Dep 100% + 100FS</h2>
          </div>
          <div class="arrow_block">
            <button class="arrow_left">
                <span>
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
                    ></path>
                  </svg>
                </span>
            </button>
            <button class="arrow_right">
                <span>
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
                    ></path>
                  </svg>
                </span>
            </button>
          </div>
        </div>
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-row">
            <div class="form-group row-select">
              <label for="tel-code">Код</label>
              <select id="tel-code" v-model="telCode">
                <option value="+7" selected>+7</option>
                <option value="+380">+380</option>
              </select>
            </div>
            <div class="form-group row-input">
              <label for="phone">Номер телефона</label>
              <input type="tel" id="phone" placeholder="Номер телефона" required v-model="phone" />
            </div>
          </div>

          <div class="form-group">
            <label for="password">Пароль *</label>
            <input type="password" id="password" placeholder="Пароль" required v-model="password" />
            <div class="shadowPassword"></div>
          </div>

          <div class="form-row">
            <div class="form-group row-select">
              <label for="currency">Валюта *</label>
              <select id="currency" v-model="currency">
                <option value="RUB" selected>RUB</option>
                <option value="UAH">UAH</option>
              </select>
            </div>
            <div class="form-group form-country">
              <label for="country">Страна *</label>
              <select id="country" required v-model="country">
                <option value="RU" selected>Россия</option>
                <option value="UA">Украина</option>
              </select>
            </div>
          </div>
          <div class="form-group promo-code-block">
            <div class="promo-code-check">
              <div id="toggle" for="promo">
                Есть промокод?
                <img
                  src="https://gama.casino/static/images/wlc/icons/arrow-circle-color.svg"
                />
              </div>
            </div>

            <div class="form-group promo-code">
              <label for="promo">Промокод</label>
              <input
                type="text"
                id="promo"
                placeholder="Промокод"
                v-model="promocode"
              />
            </div>
          </div>

          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" checked id="check" required />
              <p>
                Я согласен(-на) с <a href="#"> Условиями и положениями </a> и
                я подтверждаю, что мне есть 18 лет
              </p>
            </label>
          </div>
          <button class="submit-btn">Регистрация</button>
        </form>
        <div class="sign-in">
          <p>Есть аккаунт?</p>
          <a href="#">
            Войти
          </a>
        </div>
        <div class="block-close">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 16 16"
            fill="#FFFFFF"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'TOR',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
